import styled, { keyframes } from "styled-components";

export default function Preloader() {
  return (
    <MainWrapper>
      <StyledImage src={require("../images/ingenium.svg").default} />
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
  position: relative;
  background: rgb(151, 40, 213);
  background: linear-gradient(
    135deg,
    rgba(151, 40, 213, 1) 0%,
    rgba(103, 16, 205, 1) 35%,
    rgba(36, 29, 46, 1) 100%
  );

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledImage = styled.img`
  animation: ${pulse} 2s ease-in-out infinite;
`;
