import { Route, Routes, useLocation } from "react-router-dom";
import Home from "pages/home";
import PostSale from "pages/preview";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "styles/GlobalStyles";
import { theme } from "styles/Theme";
import { useEffect, useState } from "react";
import axios from "axios";
import { QUEUE_TOKEN } from "constants/StorageKeys";
import Preloader from "components/Preloader";
import getTheme from "functions/theme/getTheme";
import { themeDataState, themeLoadingState } from "recoil/theme";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import enterWaitingRoom from "functions/queue/enterWaitingRoom";
import {
  acceptTokenState,
  queueStatusState,
  queueUserIdState,
} from "recoil/queue";
import { QueueStatus } from "types/queue";

function App() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queueToken = queryParams.get("token");
  const [themeLoading, setThemeLoading] = useRecoilState(themeLoadingState);
  const [themeData, setThemeData] = useRecoilState(themeDataState);
  const setQueueUserId = useSetRecoilState(queueUserIdState);
  const acceptToken = useRecoilValue(acceptTokenState);
  const queueStatus = useRecoilValue(queueStatusState);

  useEffect(() => {
    if (queueToken) {
      (async () => {
        if (!themeLoading) {
          try {
            setThemeLoading(true);
            const theme = await getTheme(queueToken);
            setThemeData(theme);
            localStorage.setItem(QUEUE_TOKEN, queueToken);

            const resp = await enterWaitingRoom(queueToken);
            setQueueUserId(resp.queueUserId);
            if (resp.queueUserId === "VIP") {
              window.location.href = `${process.env.REACT_APP_INGENIUM_URL}/primary/event/${resp?.eventId}/listings?acceptToken=${resp?.token}`;
            }
          } catch (e) {
            console.log(e);
            // window.location.href = "https://ingenium.biz";
          } finally {
            setThemeLoading(false);
          }
        }
      })();
    }
  }, [queueToken]);

  useEffect(() => {
    if (acceptToken && queueStatus === QueueStatus.SALE) {
      window.location.href = `${process.env.REACT_APP_INGENIUM_URL}/primary/event/${themeData?.eventId}/listings?acceptToken=${acceptToken}`;
    }
  }, [acceptToken, queueStatus]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {themeLoading ? (
        <Preloader />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/preview" element={<PostSale />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}

export default App;
