import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

import Main from "components/MainWrapper";

const PostSale: React.FC = () => {
  const [mins, setMins] = useState<number>(29);
  const [secs, setSecs] = useState<number>(30);
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    const interval = setInterval(() => {
      if (secs > 0) {
        setSecs(secs - 1);
      } else {
        if (mins === 0) {
          clearInterval(interval);
        } else {
          setMins(mins - 1);
          setSecs(59);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [mins, secs]);

  return (
    <Main>
      <Container>
        <FormBox>
          <Heading>Tickets for today’s sale have all sold out.</Heading>
          <Subtext>
            Sign up for our waitlist to get notified as soon as tickets become
            available.
          </Subtext>
          <EmailForm>
            <EmailInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              type="email"
              placeholder="Your email"
            ></EmailInput>
            <SubmitButton>Submit</SubmitButton>
          </EmailForm>
        </FormBox>
        <BottomSvgWrapper>
          <img src={require("../../images/logo.svg").default} />
        </BottomSvgWrapper>
      </Container>
    </Main>
  );
};

const Container = styled.div`
  display: flex;
  min-height: 100dvh;
  flex-direction: column;
  justify-content: space-between;

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
`;

const BottomSvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding-bottom: 12px;
  margin-top: 2rem;

  img {
    height: 85px;
    object-fit: contain;
  }

  @media (max-width: 450px) {
    svg {
      width: 70vw;
    }

    margin-top: auto;
  }
`;

const FormBox = styled.div`
  display: flex;
  padding-top: 18dvh;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @supports not (padding-top: 18dvh) {
    padding-top: 18vh;
  }

  @media (max-width: 450px) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    padding-top: 0px;
    height: max-content;
    z-index: 1;
  }
`;

const Heading = styled.div`
  z-index: 9;
  width: 741px;
  height: 198px;
  font-size: 66px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  @media (max-width: 450px) {
    font-size: 4.65vw;
    height: auto;
    width: 90vw;
    line-height: 30px;
  }
`;

const Subtext = styled.div`
  z-index: 9;
  width: 893px;
  height: 132px;
  font-family: Poppins;
  font-size: 44px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 41px;
  @media (max-width: 450px) {
    height: auto;
    font-size: 4.65vw;
    margin: 0px;
    width: 90vw;
    line-height: 30px;
    margin-top: 14px;
  }
`;

const EmailForm = styled.div`
  z-index: 9;
  margin-top: 68px;
  width: 637px;
  height: 61px;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const EmailInput = styled.input`
  z-index: 9;
  width: 461px;
  height: 61px;
  border-radius: 5px;
  padding: 4px 0px 4px 10px;
  font-family: inherit;
  border: none;
  outline: none;
  font-size: 22px;
  line-height: 33px;
  color: #000;
  font-weight: 300;
  margin-right: 16px;
  padding-left: 25px;
  ::placeholder {
    color: #7c81a4;
  }
  @media (max-width: 450px) {
    width: 90vw;
    height: unset;
    margin-bottom: 20px;
    font-size: 16px;
    min-height: 48px;
    margin-right: 0px;
  }
`;

const SubmitButton = styled.button`
  z-index: 9;
  cursor: pointer;
  background: ${(props) => props.theme.colors.brightPurple};
  width: 167px;
  height: 61px;
  border-radius: 3px;
  font-family: inherit;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: ${(props) => props.theme.colors.textColorPrimary};
  border: none;
  padding: 4px 11px 4px 11px;
  @media (max-width: 450px) {
    min-height: 48px;
    width: 90vw;
    font-size: 18px;
    font-weight: 500;
    font-size: 16px;
    height: 41px;
  }
`;

export default PostSale;
