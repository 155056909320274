import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  html,body{
    overflow-x: hidden;
    position: relative;
  }

  body {
    min-height: 100dvh;
    color: #fff;
    font-family: "Poppins", sans-serif;
  }

  @supports not (min-height: 100dvh) {
    body {
      min-height: 100vh;
    }
  }
`;
