export const IngeniumLogoSvg = () => (
  <svg
    width="279"
    height="44"
    viewBox="0 0 279 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.1897 5.80051C68.6737 5.27876 68.4158 4.65556 68.4158 3.9309C68.4158 3.19175 68.6737 2.5613 69.1897 2.03955C69.7056 1.5178 70.332 1.25692 71.0691 1.25692C71.8061 1.25692 72.4326 1.5178 72.9485 2.03955C73.4644 2.5613 73.7224 3.19175 73.7224 3.9309C73.7224 4.65556 73.4644 5.27876 72.9485 5.80051C72.4326 6.32227 71.8061 6.58314 71.0691 6.58314C70.332 6.58314 69.7056 6.32227 69.1897 5.80051ZM68.858 35.3882V11.692L73.0591 9.7354H73.2802V33.4317L69.0791 35.3882H68.858Z"
      fill="#F6F7FF"
    />
    <path
      d="M81.019 35.3882V15.4964C81.019 13.5543 82.3972 12.0615 85.1537 11.018C86.6425 10.4528 88.3229 10.1702 90.195 10.1702C92.0671 10.1702 93.7475 10.4528 95.2363 11.018C97.9928 12.0615 99.371 13.5543 99.371 15.4964V33.4317L95.1699 35.3882H94.9488V16.2573C94.9488 15.5326 94.4845 14.9892 93.5559 14.6268C92.6419 14.2645 91.5217 14.0833 90.195 14.0833C88.8683 14.0833 87.7407 14.2645 86.812 14.6268C85.8981 14.9892 85.4412 15.5326 85.4412 16.2573V33.4317L81.2401 35.3882H81.019Z"
      fill="#F6F7FF"
    />
    <path
      d="M106.004 29.6272V15.4964C106.004 13.5543 107.383 12.0615 110.139 11.018C111.628 10.4528 113.308 10.1702 115.18 10.1702C117.052 10.1702 118.733 10.4528 120.222 11.018C122.978 12.0905 124.356 13.5833 124.356 15.4964V38.5405C124.356 40.4826 122.978 41.9754 120.222 43.0189C118.733 43.5841 117.052 43.8667 115.18 43.8667H108.304V43.6493L110.073 39.9536H115.18C116.507 39.9536 117.627 39.7724 118.541 39.4101C119.47 39.0477 119.934 38.5043 119.934 37.7796V34.2143C118.519 34.7071 116.934 34.9534 115.18 34.9534C113.308 34.9534 111.628 34.6708 110.139 34.1056C107.383 33.0331 106.004 31.5403 106.004 29.6272ZM110.426 28.8663C110.426 29.591 110.883 30.1345 111.797 30.4968C112.726 30.8591 113.854 31.0403 115.18 31.0403C116.522 31.0403 117.649 30.8591 118.563 30.4968C119.477 30.1345 119.934 29.591 119.934 28.8663V16.2573C119.934 15.5326 119.47 14.9892 118.541 14.6268C117.627 14.2645 116.507 14.0833 115.18 14.0833C113.854 14.0833 112.726 14.2645 111.797 14.6268C110.883 14.9892 110.426 15.5326 110.426 16.2573V28.8663Z"
      fill="#F6F7FF"
    />
    <path
      d="M150.005 24.888V29.6272C150.005 31.5403 148.627 33.0331 145.87 34.1056C144.381 34.6708 142.701 34.9534 140.829 34.9534C138.957 34.9534 137.276 34.6708 135.788 34.1056C133.031 33.0331 131.653 31.5403 131.653 29.6272V15.4964C131.653 13.5833 133.031 12.0905 135.788 11.018C137.276 10.4528 138.957 10.1702 140.829 10.1702C142.701 10.1702 144.381 10.4528 145.87 11.018C148.627 12.0905 150.005 13.5833 150.005 15.4964V18.2791L136.075 28.8663C136.075 29.591 136.532 30.1345 137.446 30.4968C138.375 30.8591 139.502 31.0403 140.829 31.0403C142.156 31.0403 143.276 30.8591 144.19 30.4968C145.118 30.1345 145.583 29.591 145.583 28.8663V26.8445L149.784 24.888H150.005ZM136.075 24.2793L145.583 16.7573V16.2573C145.583 15.5326 145.118 14.9892 144.19 14.6268C143.276 14.2645 142.156 14.0833 140.829 14.0833C139.502 14.0833 138.375 14.2645 137.446 14.6268C136.532 14.9892 136.075 15.5326 136.075 16.2573V24.2793Z"
      fill="#F6F7FF"
    />
    <path
      d="M156.638 35.3882V15.4964C156.638 13.5543 158.016 12.0615 160.773 11.018C162.262 10.4528 163.942 10.1702 165.814 10.1702C167.686 10.1702 169.367 10.4528 170.856 11.018C173.612 12.0615 174.99 13.5543 174.99 15.4964V33.4317L170.789 35.3882H170.568V16.2573C170.568 15.5326 170.104 14.9892 169.175 14.6268C168.261 14.2645 167.141 14.0833 165.814 14.0833C164.488 14.0833 163.36 14.2645 162.431 14.6268C161.517 14.9892 161.06 15.5326 161.06 16.2573V33.4317L156.859 35.3882H156.638Z"
      fill="#F6F7FF"
    />
    <path
      d="M183.061 5.80051C182.545 5.27876 182.287 4.65556 182.287 3.9309C182.287 3.19175 182.545 2.5613 183.061 2.03955C183.577 1.5178 184.203 1.25692 184.94 1.25692C185.677 1.25692 186.304 1.5178 186.82 2.03955C187.336 2.5613 187.593 3.19175 187.593 3.9309C187.593 4.65556 187.336 5.27876 186.82 5.80051C186.304 6.32227 185.677 6.58314 184.94 6.58314C184.203 6.58314 183.577 6.32227 183.061 5.80051ZM182.729 35.3882V11.692L186.93 9.7354H187.151V33.4317L182.95 35.3882H182.729Z"
      fill="#F6F7FF"
    />
    <path
      d="M194.89 11.692L199.091 9.7354H199.312V28.8663C199.312 29.591 199.769 30.1345 200.683 30.4968C201.612 30.8591 202.739 31.0403 204.066 31.0403C205.393 31.0403 206.513 30.8591 207.427 30.4968C208.356 30.1345 208.82 29.591 208.82 28.8663V11.692L213.021 9.7354H213.242V29.6272C213.242 31.5693 211.864 33.0621 209.107 34.1056C207.619 34.6708 205.938 34.9534 204.066 34.9534C202.194 34.9534 200.514 34.6708 199.025 34.1056C196.268 33.0621 194.89 31.5693 194.89 29.6272V11.692Z"
      fill="#F6F7FF"
    />
    <path
      d="M219.875 35.3882V15.4964C219.875 13.5543 221.254 12.0615 224.01 11.018C225.499 10.4528 227.179 10.1702 229.051 10.1702C230.894 10.1702 232.574 10.4528 234.093 11.018C234.83 11.2934 235.471 11.605 236.016 11.9528C236.562 11.605 237.203 11.2934 237.94 11.018C239.458 10.4528 241.139 10.1702 242.981 10.1702C244.853 10.1702 246.534 10.4528 248.023 11.018C250.779 12.0615 252.157 13.5543 252.157 15.4964V33.4317L247.956 35.3882H247.735V16.2573C247.735 15.5326 247.271 14.9892 246.342 14.6268C245.428 14.2645 244.308 14.0833 242.981 14.0833C241.655 14.0833 240.527 14.2645 239.598 14.6268C238.684 14.9892 238.227 15.5326 238.227 16.2573V33.4317L234.026 35.3882H233.805V16.2573C233.805 15.5326 233.341 14.9892 232.412 14.6268C231.498 14.2645 230.378 14.0833 229.051 14.0833C227.725 14.0833 226.597 14.2645 225.668 14.6268C224.755 14.9892 224.298 15.5326 224.298 16.2573V33.4317L220.096 35.3882H219.875Z"
      fill="#F6F7FF"
    />
    <path
      d="M270.907 8.17014V16.1269H279V16.3443L277.209 20.0835H270.907V26.2793L267.104 28.0402H266.883V20.0835H258.791V19.8661L260.582 16.1269H266.883V9.93106L270.686 8.17014H270.907Z"
      fill="#F6F7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8582 0.120915C12.8236 1.47454 3.64179 9.32299 0.900318 19.7447C0.073413 22.888 -0.0484533 24.918 0.0139443 34.4912L0.0703003 43.1368L0.583759 43.5684L1.09722 44H12.9626C23.9199 44 24.9872 43.9732 26.9051 43.6494C32.7002 42.6709 37.9191 39.9318 41.9826 35.7357C45.4373 32.1681 47.689 27.9307 48.8537 22.8049C49.2407 21.1014 49.2637 20.5416 49.3358 11.0442L49.4116 1.0721L48.8632 0.53605L48.3148 0L36.361 0.0268336C29.7864 0.0415003 24.1601 0.0838907 23.8582 0.120915ZM45.9078 5.81301V8.22764H37.8807H29.8535L24.299 12.1921C21.2441 14.3726 18.4726 16.3135 18.1402 16.5052C17.8079 16.6969 17.4556 17.0003 17.3575 17.1793C17.2501 17.3755 17.1793 20.4253 17.1793 24.85V32.1951H10.2936H3.40793L3.50802 27.7683C3.61342 23.1165 3.82916 21.5611 4.72853 18.9728C6.76478 13.1127 11.6832 7.8472 17.4137 5.39286C19.3166 4.57779 21.858 3.82675 23.4923 3.59673C24.0961 3.51159 29.3867 3.43218 35.249 3.4202L45.9078 3.39837V5.81301ZM38.9343 14.0349L35.5692 16.4438L29.9119 16.4496C26.8004 16.4528 24.289 16.4247 24.3309 16.3873C24.373 16.3498 25.8884 15.2632 27.6989 13.9727L30.9908 11.626H36.645H42.2992L38.9343 14.0349ZM45.854 17.126C45.7667 20.1942 45.6701 21.1629 45.297 22.7154C44.1835 27.3485 42.0046 31.0654 38.5021 34.306C34.1011 38.378 28.435 40.5988 22.4401 40.6009L20.473 40.6016V38.2228V35.8441L22.4915 35.6246C30.9573 34.7045 37.1217 28.73 38.041 20.5539C38.1564 19.5269 38.3467 18.6361 38.4651 18.5685C38.5833 18.5011 40.2444 17.3162 42.1566 15.9352C44.0688 14.5543 45.7067 13.4223 45.7965 13.4195C45.8902 13.4168 45.9148 14.9929 45.854 17.126ZM34.4916 20.8648C33.9255 25.5728 30.1618 30.0019 25.4136 31.5482C24.0149 32.0038 20.966 32.4718 20.6483 32.2799C20.542 32.2157 20.473 29.7494 20.473 26.0138V19.8537H27.5431H34.6131L34.4916 20.8648ZM17.1793 38.187V40.6016H10.3174H3.45551V38.187V35.7724H10.3174H17.1793V38.187Z"
      fill="#F6F7FF"
    />
  </svg>
);
