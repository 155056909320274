import styled from "styled-components";

interface AnimatedProgressBarProps {
  progress: number;
}

export default function AnimatedProgressBar({
  progress,
}: AnimatedProgressBarProps) {
  return (
    <ProgressBar>
      <Progress width={`${progress * 100}%`} />
      <ProgressBarBg width={`${progress * 100}%`} />
    </ProgressBar>
  );
}

const ProgressBar = styled.div`
  width: 100%;
  height: 7px;
  border-radius: 5px;
  background: #5d5765;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) inset;
  position: relative;
`;

const ProgressBarBg = styled.div<{ width: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => props.width};
  border-radius: 5px;
  background: linear-gradient(270deg, #8d62ff 0%, rgba(141, 98, 255, 0) 100%);
  animation: progress 3s ease-in-out infinite;

  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: ${(props) => props.width};
    }
  }
`;

const Progress = styled.div<{ width: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 5px;
  width: ${(props) => props.width};
  background: linear-gradient(270deg, #8d62ff 0%, rgba(141, 98, 255, 0) 100%);
  z-index: 1;
  transition: width 0.3s ease-in-out;
`;
