export interface IQueueUserStatus {
  mode: QueueStatus;
  captchaId?: string;
  captchaQuestion?: string;
  estimatedTime?: number;
  position?: number;
  token?: string;
  totalUsers?: number;
  usersAhead?: number;
}

export enum QueueStatus {
  BLOCKED = "blocked",
  QUEUE = "queue",
  SALE = "sale",
  SOLVE_CAPTCHA = "solveCaptcha",
  WAITING_ROOM = "waitingRoom",
}
