import axios from "axios";
import { IQueueUserStatus } from "types/queue";

export default async function checkCaptcha(
  captchaId: string,
  answer: string,
  queueToken: string | null
): Promise<IQueueUserStatus> {
  const resp = await axios.post(
    `${process.env.REACT_APP_FAIRNESS_API}/queue/solve-captcha`,
    { answer, captchaId },
    {
      headers: { token: queueToken },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }

  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
