import axios from "axios";
import { IWaitingRoomTheme } from "types/theme";

export default async function getTheme(
  queueToken: string
): Promise<IWaitingRoomTheme> {
  const resp = await axios.get(`${process.env.REACT_APP_FAIRNESS_API}/theme`, {
    headers: { token: queueToken },
  });

  if (!resp.data) {
    throw new Error("Some error occured");
  }

  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
