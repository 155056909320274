import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { IQueueUserStatus, QueueStatus } from "types/queue";

const { persistAtom } = recoilPersist();

export const isQueueEntered = atom<boolean>({
  default: false,
  effects_UNSTABLE: [persistAtom],
  key: "isQueueEntered",
});

export const queueUserIdState = atom<string>({
  default: undefined,
  effects_UNSTABLE: [persistAtom],
  key: "queueUserIdState",
});

export const queueUserStatusState = atom<IQueueUserStatus>({
  default: undefined,
  key: "queueUserStatusState",
});

export const queueStatusState = atom<QueueStatus>({
  default: QueueStatus.WAITING_ROOM,
  key: "queueStatusState",
});

export const lastStatusUpdateTimeState = atom<number>({
  default: 0,
  key: "lastStatusUpdateTimeState",
});

export const acceptTokenState = atom<string>({
  default: "",
  key: "acceptTokenState",
});
