import axios from "axios";
import { IQueueUserStatus } from "types/queue";

export default async function getQueueStatus(
  queueToken: string
): Promise<IQueueUserStatus> {
  const resp = await axios.get(
    `${process.env.REACT_APP_FAIRNESS_API}/queue/position`,
    {
      headers: { token: queueToken },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }

  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
