import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    colors: {
      brightPurple: string;
      modalBackgroundPrimary: string;
      textColorPrimary: string;
    };
  }
}

export const theme: DefaultTheme = {
  colors: {
    brightPurple: "#7b2fdb",
    modalBackgroundPrimary: "#35394F",
    textColorPrimary: "#FFFFFF",
  },
};
