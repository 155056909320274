import { IngeniumLogoSvg } from "constants/index";
import styled from "styled-components";

import { theme } from "../styles/Theme";

import { themeDataState } from "recoil/theme";
import { useRecoilState, useRecoilValue } from "recoil";
import { useState } from "react";
import { queueStatusState, queueUserStatusState } from "recoil/queue";
import checkCaptcha from "functions/captcha/checkCaptcha";
import { QUEUE_TOKEN } from "constants/StorageKeys";
import { QueueStatus } from "types/queue";

interface ModalProps {
  onClose: () => void;
}

const CaptchaModal: React.FC<ModalProps> = () => {
  const themeData = useRecoilValue(themeDataState);
  const queueToken = localStorage.getItem(QUEUE_TOKEN);
  const [queueStatus, setQueueStatus] = useRecoilState(queueStatusState);
  const [statusData, setStatusData] = useRecoilState(queueUserStatusState);
  const [loading, setLoading] = useState(false);
  const options = themeData?.options;
  const [error, setError] = useState(false);
  const [answer, setAnswer] = useState("");

  const handleCaptchaSubmit = async () => {
    try {
      setError(false);
      if (!loading && !!answer && statusData.captchaId) {
        setLoading(true);
        const resp = await checkCaptcha(
          statusData.captchaId,
          answer,
          queueToken
        );

        setQueueStatus(resp.mode);
        setStatusData(resp);
        if (
          resp.mode === QueueStatus.SALE ||
          resp.mode === QueueStatus.BLOCKED
        ) {
          if (resp.token && resp.mode === QueueStatus.SALE) {
            window.location.href = `${process.env.REACT_APP_INGENIUM_URL}/primary/event/${themeData?.eventId}/listings?acceptToken=${resp.token}`;
          }
        }
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <TopGradientBar />
        <Pad>
          {options?.languageSelector && (
            <LanguageDiv>
              <LanguageButton>English (United Kingdom)</LanguageButton>
            </LanguageDiv>
          )}

          <Acknowledgement>
            <div>
              <center>
                <IngeniumLogoSvg />
              </center>
              {options?.logo && (
                <Logo>
                  <img src={themeData?.logoUrl} />
                </Logo>
              )}
            </div>

            <SolveCaptchaText>Solve Captcha to continue</SolveCaptchaText>

            <Column>
              <QueueID>
                Captcha ID: <span> {statusData?.captchaId}</span>
              </QueueID>

              <SmallLogo>
                <IngeniumLogoSvg />
              </SmallLogo>
            </Column>
          </Acknowledgement>
          <QueueStats>
            <Question>{statusData?.captchaQuestion}</Question>

            <Hr />

            <AnswerInput
              type="text"
              value={answer}
              onFocus={() => setError(false)}
              onChange={(e) => setAnswer(e.target.value)}
            />

            <SubmitButton onClick={handleCaptchaSubmit}>Submit</SubmitButton>

            {error && <Error>Verification failed. Please try again.</Error>}
          </QueueStats>
        </Pad>

        <BottomGradientBar />
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  @media (min-height: 900px) {
    align-items: center;
  }
`;

const QueueID = styled.div`
  color: #f6f7ff;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    text-decoration: underline;
  }
`;

const LanguageDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Pad = styled.div`
  padding: 12px 20px 5px 20px;
`;

const ModalContent = styled.div`
  background-color: ${theme.colors.modalBackgroundPrimary};
  max-width: 535px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: relative;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 95%;
  }
`;

const LanguageButton = styled.button`
  background: ${theme.colors.modalBackgroundPrimary};
  color: #fff;
  max-width: 133px;
  font-family: Poppins;
  height: 18px;
  border-radius: 3px;
  border: 0.5px solid #fff;
  font-size: 8px;
  line-height: 12px;
  font-weight: 300;
  padding: 3px 15px 3px 14px;
`;

const Acknowledgement = styled.div`
  width: 100%;
  min-height: 208px;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 0px #00000026;
  margin: 20px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 12px;
  padding-bottom: 10px;

  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      width: 40vw;
    }
  }
`;

const QueueStats = styled.div`
  max-width: 505px;
  height: 259px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 0px #00000026;
  margin: 0px 0px 20px 0px;
  position: relative;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Logo = styled.div`
  font-size: 33px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: 450px) {
    display: none;
  }

  img {
    height: 42px;
    object-fit: contain;
  }
`;

const SubmitButton = styled.button`
  background: ${theme.colors.brightPurple};
  width: 90px;
  height: 25px;
  display: block;
  border-radius: 3px;
  margin: auto;
  font-family: Poppins;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: ${theme.colors.textColorPrimary};
  cursor: pointer;
  border: none;
  padding: 4px 11px 4px 11px;
  @media (max-width: 450px) {
    font-size: 8px;
  }
`;

const TopGradientBar = styled.div`
  border-radius: 5px 5px 0px 0px;
  background: linear-gradient(180deg, #7000ff 0%, #8861c8 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 20px;
  width: 100%;
`;

const BottomGradientBar = styled(TopGradientBar)`
  border-radius: 0px 0px 5px 5px;
  background: linear-gradient(0deg, #7000ff 0%, #8861c8 100%);
`;

const SmallLogo = styled.div`
  width: max-content;
  svg {
    height: 14px;
    width: 90px;
    margin-left: auto;
    transform: translateY(4px);
  }
`;

const Column = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const SolveCaptchaText = styled.p`
  color: #f6f7ff;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Question = styled.p`
  color: #f6f7ff;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 1.5625rem;
`;

const Hr = styled.div`
  background: #454963;
  height: 1px;
  width: 90%;
  margin: auto;
  margin: 1.5625rem;
  margin-bottom: 1.5625rem;
`;

const AnswerInput = styled.input`
  display: block;
  border-radius: 5px;
  border: 1px solid #454963;
  background-color: transparent;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  height: 2.5rem;
  max-width: 17.125rem;
  padding: 0 0.5rem;
  font-family: inherit;
  color: #fff;
  text-align: center;
  margin: auto;

  &::placeholder {
    color: #7c81a4;
  }
`;

const Error = styled.div`
  color: #ea6768;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
`;

export default CaptchaModal;
