import axios from "axios";

export default async function enterWaitingRoom(
  queueToken: string
): Promise<{ queueUserId: string; eventId?: string; token?: string }> {
  const resp = await axios.get(
    `${process.env.REACT_APP_FAIRNESS_API}/queue/enter`,
    {
      headers: { token: queueToken },
    }
  );

  if (!resp.data) {
    throw new Error("Some error occured");
  }

  if (resp.data.error) {
    throw new Error(resp.data.error);
  }

  return resp.data;
}
