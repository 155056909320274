import React from "react";
import { motion } from "framer-motion";
import bgLocal from "images/bg.webp";
import styled from "styled-components";

export default function Main({
  bg,
  children,
}: {
  children: React.ReactNode;
  bg?: string;
}) {
  return (
    <MainWrapper>
      <Background $bg={bg || bgLocal} />
      <BackDrop
        initial={{ opacity: 1 }}
        animate={{ opacity: 0.66 }}
        transition={{ delay: 0.75, duration: 0.75 }}
      />
      {children}
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
  position: relative;

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
`;

const BackDrop = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  background-color: #000;
`;

const Background = styled.div<{ $bg: string }>`
  background: url(${({ $bg }) => $bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
