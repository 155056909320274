import React from "react";
import Slider, { Settings } from "react-slick";
import img2 from "images/boy-dynamic-gradient.svg";
import img3 from "images/explorer-dynamic-gradient.svg";
import img1 from "images/hash-dynamic-gradient.svg";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { themeDataState } from "recoil/theme";
import { useRecoilValue } from "recoil";
import { IQueueUserStatus } from "types/queue";

const CarouselComponent = ({
  estimatedTime,
  position,
  usersAhead,
}: IQueueUserStatus) => {
  const themeData = useRecoilValue(themeDataState);
  const options = themeData?.options;

  const settings: Settings = {
    appendDots: (dots: React.ReactNode) => <CustomDots>{dots}</CustomDots>,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    slide: "div",
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  };

  return (
    <Slider {...settings}>
      {options?.queueNumber && (
        <div>
          <StatsBoxContent
            imgSrc={img1}
            title={position || 0}
            subText="Your queue number"
          />
        </div>
      )}

      {options?.usersAhead && (
        <div>
          <StatsBoxContent
            imgSrc={img2}
            title={usersAhead || 0}
            subText="Number of users in queue ahead of you"
          />
        </div>
      )}

      {options?.waitingTime && (
        <div>
          <StatsBoxContent
            imgSrc={img3}
            title={`${estimatedTime || 0} minutes`}
            subText="Your estimated wait times"
          />
        </div>
      )}
    </Slider>
  );
};

const StatsBoxContent = ({
  imgSrc,
  subText,
  title,
}: {
  imgSrc: string;
  subText: string;
  title: string | number;
}) => {
  return (
    <StatBox>
      <ImageContainer>
        <img src={imgSrc} alt="" height={55} width={55} />
      </ImageContainer>
      <Stats>
        <h3>{title}</h3>
        <p>{subText}</p>
      </Stats>
    </StatBox>
  );
};

const ImageContainer = styled.div`
  margin-right: 6px;
`;

const CustomDots = styled.ul`
  &.slick-dots {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    bottom: 14px;
  }

  li {
    display: inline-block !important;
    margin: 0 2px !important;
    width: 12px !important;
    height: 12px !important;

    button {
      width: 12px !important;
      height: 12px !important;
      color: #454963 !important;

      &::before {
        border: none !important;
        width: 12px !important;
        height: 12px !important;
        font-size: 10px !important;
        opacity: 1 !important;
        color: #454963 !important;
      }
    }

    &.slick-active {
      button {
        &::before {
          opacity: 1 !important;
          color: #7b34d7 !important;
        }
      }
    }
  }
`;

const StatBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 152px;
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: centre;
  align-items: center;

  p {
    font-weight: 300;
  }
`;

export default CarouselComponent;
