import getQueueStatus from "functions/queue/getQueueStatus";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  acceptTokenState,
  lastStatusUpdateTimeState,
  queueStatusState,
  queueUserStatusState,
} from "recoil/queue";
import { themeDataState } from "recoil/theme";
import { QueueStatus } from "types/queue";

export function useStatusPolling(
  queueToken: string | null,
  waitingTimeInMS: number
) {
  const [queueStatus, setQueueStatus] = useRecoilState(queueStatusState);
  const [statusData, setStatusData] = useRecoilState(queueUserStatusState);
  const setLastStatusUpdateTime = useSetRecoilState(lastStatusUpdateTimeState);
  const setAcceptToken = useSetRecoilState(acceptTokenState);
  const themeData = useRecoilValue(themeDataState);

  useEffect(() => {
    let isMounted = true;

    async function pollStatus() {
      try {
        while (
          queueToken &&
          isMounted &&
          (queueStatus === QueueStatus.WAITING_ROOM ||
            queueStatus === QueueStatus.QUEUE)
        ) {
          const statusData = await getQueueStatus(queueToken);
          setQueueStatus(statusData.mode);
          setStatusData(statusData);
          setLastStatusUpdateTime(Date.now());
          if (
            statusData.mode === QueueStatus.SALE ||
            statusData.mode === QueueStatus.BLOCKED
          ) {
            if (statusData.token && statusData.mode === QueueStatus.SALE) {
              setAcceptToken(statusData.token);
            }
          }
          await new Promise((resolve) => setTimeout(resolve, waitingTimeInMS)); // Wait for 10 seconds
        }
      } catch (e: unknown) {
        // const errMsg = axiosErrorHandler(e);
        // setErrorMsg(errMsg);
        // router.push("/error");
        console.log(e);
      }
    }

    if (
      queueToken &&
      (queueStatus === QueueStatus.WAITING_ROOM ||
        queueStatus === QueueStatus.QUEUE)
    ) {
      setTimeout(() => pollStatus(), 2000);
    }

    return () => {
      isMounted = false;
    };
  }, [queueToken, queueStatus, setQueueStatus]);

  return {
    queueStatus: statusData?.mode,
    setQueueStatus,
    statusData,
  };
}
