import { IngeniumLogoSvg } from "constants/index";
import styled from "styled-components";

import { theme } from "../styles/Theme";

import AnimatedProgressBar from "./AnimatedProgressBar";
import CarouselComponent from "./Carousel";
import { themeDataState } from "recoil/theme";
import { useRecoilValue } from "recoil";
import { lastStatusUpdateTimeState, queueUserStatusState } from "recoil/queue";

interface ModalProps {
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ onClose }) => {
  const statusData = useRecoilValue(queueUserStatusState);
  const lastUpdateTime = useRecoilValue(lastStatusUpdateTimeState);
  const themeData = useRecoilValue(themeDataState);
  const options = themeData?.options;

  return (
    <ModalWrapper>
      <ModalContent>
        <TopGradientBar />
        <Pad>
          {options?.languageSelector && (
            <LanguageDiv>
              <LanguageButton>English (United Kingdom)</LanguageButton>
            </LanguageDiv>
          )}

          <Acknowledgement>
            <div>
              <center>
                <IngeniumLogoSvg />
              </center>
              {options?.logo && (
                <Logo>
                  <img src={themeData?.logoUrl} />
                </Logo>
              )}
            </div>
            <TextArea>
              <AcknowledgementText>You are now in queue</AcknowledgementText>
              <AcknowledgementSubtext>
                You are now in queue for {themeData?.eventName}. When it is your
                turn, you will have 10 minutes to enter the website.
              </AcknowledgementSubtext>
            </TextArea>
          </Acknowledgement>
          <QueueStats>
            {options?.progressBar && (
              <HorizontalPad>
                <ProgressWrapper>
                  <AnimatedProgressBar progress={0.5} />
                </ProgressWrapper>
              </HorizontalPad>
            )}

            <CarouselComponent {...statusData} />
            <LastStatusUpdateTimerText>
              Last status update:{" "}
              {new Date(lastUpdateTime).toLocaleTimeString()}
            </LastStatusUpdateTimerText>
          </QueueStats>
          {(options?.dynamicMessage || options?.emailNotification) && (
            <LastUpdate>
              {options?.dynamicMessage && (
                <LastMessageUpdate>
                  <LastMessageUpdateHeader>
                    Last message update: 16:35 SGT
                  </LastMessageUpdateHeader>
                  <LastMessageUpdateSubtext>
                    Test Message
                  </LastMessageUpdateSubtext>
                </LastMessageUpdate>
              )}

              {options?.emailNotification && (
                <NotificationBox>
                  <NotificationHeader>
                    Please notify me when it is my turn:
                  </NotificationHeader>
                  <NotificationForm>
                    <NotificationInput placeholder="Enter your email here"></NotificationInput>
                    <SubmitButton>Notify me</SubmitButton>
                  </NotificationForm>
                </NotificationBox>
              )}
            </LastUpdate>
          )}
        </Pad>
        <Row>
          <QueueID>
            Queue ID: <span>{themeData?.queueId}</span>
          </QueueID>

          <IngLogo>
            <IngeniumLogoSvg />
          </IngLogo>
        </Row>
        <BottomGradientBar />
      </ModalContent>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  @media (min-height: 900px) {
    align-items: center;
  }
`;

const QueueID = styled.div`
  color: #f6f7ff;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    text-decoration: underline;
  }
`;

const LanguageDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const IngLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    height: 14px;
    width: max-content;
  }
`;

const Pad = styled.div`
  padding: 12px 20px 5px 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 12px;
  padding-bottom: 5px;
`;

const ModalContent = styled.div`
  background-color: ${theme.colors.modalBackgroundPrimary};
  max-width: 535px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: relative;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 95%;
  }
`;

const CloseButton = styled.button``;

const LanguageButton = styled.button`
  background: ${theme.colors.modalBackgroundPrimary};
  color: #fff;
  max-width: 133px;
  font-family: Poppins;
  height: 18px;
  border-radius: 3px;
  border: 0.5px solid #fff;
  font-size: 8px;
  line-height: 12px;
  font-weight: 300;
  padding: 3px 15px 3px 14px;
`;

const Acknowledgement = styled.div`
  width: 100%;
  min-height: 208px;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 0px #00000026;
  margin: 20px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 12px;

  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      width: 40vw;
    }
  }
`;

const QueueStats = styled.div`
  max-width: 505px;
  height: 259px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 0px #00000026;
  margin: 0px 0px 20px 0px;
  position: relative;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const LastUpdate = styled.div`
  max-width: 505px;
  width: 100%;
  height: 179px;
  border-radius: 5px;
  box-shadow: 0px 0px 9px 0px #00000026;
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Logo = styled.div`
  font-size: 33px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: 450px) {
    display: none;
  }

  img {
    height: 42px;
    object-fit: contain;
  }
`;

const TextArea = styled.div`
  padding: 0px 12px 0px 12px;
`;
const AcknowledgementText = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: 450px) {
    font-size: 15px;
    line-height: 21px;
  }
`;

const AcknowledgementSubtext = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  @media (max-width: 450px) {
    font-size: 8px;
    line-height: 14px;
  }
`;

const LastMessageUpdate = styled.div`
  max-width: 453px;
  width: 100%;
  height: 70px;
  border-radius: 3px;
  box-shadow: 0px 0px 9px 0px #00000026;
  background: ${theme.colors.brightPurple};
  @media (max-width: 480px) {
    max-width: 90%;
  }
`;

const LastMessageUpdateHeader = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
`;

const LastMessageUpdateSubtext = styled.div`
  font-family: Poppins;
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10px;
`;

const NotificationBox = styled.div`
  max-width: 453px;
  width: 100%;
  margin-top: 15px;
  @media (max-width: 450px) {
    width: 90%;
  }
`;

const NotificationHeader = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 8px;
`;

const NotificationForm = styled.div`
  display: flex;
`;

const NotificationInput = styled.input`
  max-width: 223px;
  height: 23px;
  border-radius: 3px;
  border: 1px solid #454963;
  background: none;
  padding: 4px 0px 4px 10px;
  font-family: inherit;
  color: #fff;
  font-size: 10px;
  margin-right: 7px;

  ::placeholder {
    color: #7c81a4;
  }
`;

const SubmitButton = styled.button`
  background: ${theme.colors.brightPurple};
  width: 70px;
  height: 23px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: ${theme.colors.textColorPrimary};
  border: none;
  padding: 4px 11px 4px 11px;
  @media (max-width: 450px) {
    font-size: 8px;
  }
`;

const LastStatusUpdateTimerText = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
`;

const TopGradientBar = styled.div`
  border-radius: 5px 5px 0px 0px;
  background: linear-gradient(180deg, #7000ff 0%, #8861c8 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 20px;
  width: 100%;
`;

const BottomGradientBar = styled(TopGradientBar)`
  border-radius: 0px 0px 5px 5px;
  background: linear-gradient(0deg, #7000ff 0%, #8861c8 100%);
`;

const ProgressWrapper = styled.div`
  min-height: 86px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #454963;
`;

const HorizontalPad = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export default Modal;
