import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

import Main from "components/MainWrapper";
import Modal from "components/QueueModal";
import { useRecoilValue } from "recoil";
import { themeDataState } from "recoil/theme";
import { QUEUE_TOKEN } from "constants/StorageKeys";
import { useStatusPolling } from "hooks/useStatusPolling";
import { QueueStatus } from "types/queue";
import CaptchaModal from "components/CaptchaModal";

export default function Home() {
  const queueToken = localStorage.getItem(QUEUE_TOKEN);
  const [mins, setMins] = useState(29);
  const [secs, setSecs] = useState(30);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCaptchaModalOpen, setIsCaptchaModalOpen] = useState(false);
  const themeData = useRecoilValue(themeDataState);
  const { queueStatus, statusData } = useStatusPolling(queueToken, 10 * 1000);

  useEffect(() => {
    if (queueStatus === QueueStatus.QUEUE && !isModalOpen) {
      setIsModalOpen(true);
    } else if (queueStatus === QueueStatus.SOLVE_CAPTCHA) {
      setIsModalOpen(false);
      setIsCaptchaModalOpen(true);
    }
  }, [queueStatus]);

  const targetTime = themeData?.saleStartTime;

  useEffect(() => {
    const currentTime = Date.now();
    if (targetTime) {
      let totalSecs = (targetTime - currentTime) / 1000;
      let totalMins = Math.floor(totalSecs / 60);
      setSecs(Math.floor(totalSecs - totalMins * 60));
      setMins(totalMins);
    }
  }, [themeData]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      if (targetTime) {
        let totalSecs = (targetTime - currentTime) / 1000;
        let totalMins = Math.floor(totalSecs / 60);
        setSecs(Math.floor(totalSecs - totalMins * 60));
        setMins(totalMins);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [mins, secs, themeData]);

  useEffect(() => {
    if (queueToken) {
      (async () => {})();
    }
  }, [queueToken]);

  return (
    <Main bg={themeData?.bgUrl}>
      <CenterSvgWrapper
        // onClick={() => setIsModalOpen(true)}
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img src={themeData?.logoUrl} />
      </CenterSvgWrapper>
      {mins >= 0 && !isModalOpen && (
        <Time
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          TICKET SALE FOR {themeData?.eventName} Starts in {mins}:
          {String(secs).padStart(2, "0")}
        </Time>
      )}

      {isCaptchaModalOpen && (
        <CaptchaModal onClose={() => setIsCaptchaModalOpen(false)} />
      )}
      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
    </Main>
  );
}

const Time = styled(motion.div)`
  color: #fff;
  position: absolute;
  bottom: 75px;
  z-index: 1;
  font-size: 2.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  width: 100%;
  padding: 0 1rem;

  @media (max-width: 428px) {
    width: 70%;
    font-size: 16px;
    text-align: center;
    bottom: 35vh;
    padding: 0;
  }
`;

const CenterSvgWrapper = styled(motion.div)`
  position: relative;
  z-index: 2;
  margin: auto;

  img {
    object-fit: contain;
    width: 80vw;
    max-height: 20vh;
  }

  @media (max-width: 428px) {
    display: flex;
    justify-content: center;
    width: 100%;
    svg {
      width: 80%;
    }
  }
`;
