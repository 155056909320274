import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { IWaitingRoomTheme } from "types/theme";

const { persistAtom } = recoilPersist();

export const themeDataState = atom<IWaitingRoomTheme | undefined>({
  default: undefined,
  effects_UNSTABLE: [persistAtom],
  key: "themeDataState",
});

export const themeLoadingState = atom<boolean>({
  default: false,
  key: "themeLoadingState",
});
